<template>
	<v-list-tile class="upload-file-details__item px-2">
        <v-list-tile-content>
            <!-- Directory item -->
            <template v-if="file.isDirectory">
                <v-layout row justify-space-between class="upload-item__directory w-full">
					<w-icon small class="mr-2" color="primary">fas fa-folder</w-icon>
					<v-flex class="upload-item__name">
                    	<div class="upload-item__title">{{ file.name }}</div>
                    	<div v-if="file.path && file.path !== file.name" class="upload-item__path">
                        	{{ formatPath(file.path) }}
                      	</div>
                      	<div v-if="file.size" class="upload-item__size">
                        	{{ $humanReadableSize(file.size) }}
                      	</div>
                    </v-flex>
                    <v-flex v-if="file.progress < 100" shrink class="upload-item__percentage ml-2">{{ file.progression }}%</v-flex>
                    <v-flex v-else shrink>
                      	<w-icon small class="upload-item__check" color="primary">fas fa-check</w-icon>
                    </v-flex>
                </v-layout>
            </template>
                
            <!-- File item -->
            <template v-else>
                <v-layout row justify-space-between class="w-full">
                    <UploadFileItem
                        :fullpath="file.fullpath"
                      	:name="file.name"
                      	:progression="file.progression"
                      	:show-checkmark="!isUploading"
                      	:show-progress="false"
                      	:size="file.size"
                      	:type="file.type"
                      	:upload-id="file.id"
                    />
                </v-layout>
                <div v-if="file.path && file.path !== file.name" class="upload-item__path mb-1">
                	{{ formatPath(file.path) }}
                </div>
            </template>
                
            <v-progress-linear
                v-if="isUploading"
                class="upload-file-details__progress mt-1"
                :value="file.progression"
                height="4"
                color="primary"
            ></v-progress-linear>
        </v-list-tile-content>
    </v-list-tile>
</template>

<script>
export default {
	name: 'UploadFileListItem',
  components: {
    UploadFileItem: () => ({
      component: import('@/components/Documents/Upload/UploadFileItem')
    })
  },
	props: {
		value: {
			default: null,
			type: Object
		}
	},
	computed: {
		file: function () {
			return this.value ?? {}
		},
		isUploading: function () {
			return this.value.progression < 100
		}
	}
}
</script>

<style scoped>
.upload-item__directory {
  margin-bottom: 15px;
}

.w-full {
  width: 100%;
}

.upload-file-details__item ::v-deep .v-list__tile {
  padding: 0 5px;
  height: auto;
}

.upload-item__name {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  font-size: 13px;
  line-height: 1.2;
  overflow: hidden;
  padding-right: 8px;
  min-width: 0;
  text-overflow: ellipsis;
}

.upload-item__title {
  font-weight: 500;
  word-break: break-word;
}

.upload-item__path {
  font-size: 11px;
  color: rgba(0, 0, 0, 0.54);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 2px;
}

:deep(.theme--dark .upload-item__path) {
	color: rgba(255, 255, 255, 0.54);
}

.upload-item__size {
  font-size: 12px;
  white-space: nowrap;
  color: rgba(0, 0, 0, 0.54);
}

.upload-item__check {
  margin-left: 8px;
}

.upload-item__percentage {
  font-size: 12px;
  min-width: 40px;
  text-align: right;
}
</style> 